import $ from 'jquery';

export default class ImagePicker {
  static querySelector = '.image-input-container';

  constructor(domElement) {
    $(domElement).on('click', () => $(this).find('input').val(null));
    $(domElement)
      .find('.image-picker')
      .change(function change() {
        if (this.files && this.files[0]) {
          const reader = new FileReader();
          reader.onload = (e) => $(this).closest('label').find('.img-responsive').css('background-image', `url('${e.target.result}')`);
          reader.readAsDataURL(this.files[0]);
        }
      });
  }
}
