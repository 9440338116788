export default class DynamicFormHandler {
  static querySelector = '[data-js-form]';

  constructor(domElement) {
    const $form = $(domElement);
    $(domElement).submit(() => {
      const val = $form.data('js-form');
      const $inputs = $(`[data-form-attachment=${val}]`);
      $inputs.each((index, element) => {
        const dup = $(element).clone(true);
        dup.hide();
        $form.append(dup);
      });
      return true;
    });
  }
}
