export default class SelectAllCheckbox {
  static querySelector = 'body';

  constructor(domElement) {
    domElement.addEventListener('input', (event) => {
      if (!event.target.hasAttribute('select-all-group')) {
        return;
      }

      if (event.target.hasAttribute('select-all-parent')) {
        SelectAllCheckbox.onParentInput(event);
      } else {
        SelectAllCheckbox.onChildInput(event);
      }
    });
  }

  /**
   * Get child checkboxes that this 'check-all' checkbox controls.
   * @param {HTMLInputElement} parentElement
   * @returns {HTMLInputElement[]}
   */
  static getChildCheckboxElements(parentElement) {
    const group = parentElement.getAttribute('select-all-group');
    return [...document.querySelectorAll(`[select-all-group="${group}"]:not([select-all-parent])`)];
  }

  /**
   * Get the number of checked checkboxes in an array of checkbox elements.
   * @param {HTMLInputElement[]} checkboxElements
   * @returns {number}
   */
  static getCheckboxElementsCheckedCount(checkboxElements) {
    return [...checkboxElements].filter((checkboxElement) => checkboxElement.checked).length;
  }

  /**
   * Get the parent 'check-all' element.
   * @param {HTMLInputElement} childElement
   * @returns {HTMLInputElement | null}
   */
  static getParentCheckboxElement(childElement) {
    const group = childElement.getAttribute('select-all-group');
    return document.querySelector(`[select-all-group="${group}"][select-all-parent]`);
  }

  /**
   * Called when the 'check-all' checkbox is changed. The event is bubbled through body.
   * @param {HTMLInputElement} target
   */
  static onParentInput({ target }) {
    SelectAllCheckbox.getChildCheckboxElements(target).forEach((checkbox) => {
      checkbox.checked = target.checked;
    });
  }

  /**
   * Called when a child checkbox is changed.
   * @param {HTMLInputElement} target
   */
  static onChildInput({ target }) {
    const parentElement = SelectAllCheckbox.getParentCheckboxElement(target);

    if (!parentElement) {
      // eslint-disable-next-line no-console
      console.error('Check-all checkbox parent element not found', parentElement);
      return;
    }

    const childElements = SelectAllCheckbox.getChildCheckboxElements(parentElement);
    const checkedChildCount = SelectAllCheckbox.getCheckboxElementsCheckedCount(childElements);

    if (checkedChildCount === 0) {
      parentElement.indeterminate = false;
      parentElement.checked = false;
    } else if (checkedChildCount === childElements.length) {
      parentElement.indeterminate = false;
      parentElement.checked = true;
    } else {
      parentElement.indeterminate = true;
      parentElement.checked = true;
    }
  }
}
