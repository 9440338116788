<template>
  <div class="radio-button-card-group" :class="{
    'radio-button-card-group--loaded': loaded,
  }">
    <slot name="cards"></slot>
    <slot name="content"></slot>
    <slot></slot>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    initialValue: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      value: this.initialValue,
    };
  },
  provide() {
    return {
      radioButtonValue: computed(() => this.value),
      onRadioButtonChange: (value) => {
        this.value = value;
      },
    };
  },
};
</script>
