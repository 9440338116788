<template>
  <div class="box box-danger">
    <div class="box-header with-border">
      <h3 class="box-title">Price presets</h3>

      <div class="box-tools pull-right">
        <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i></button>
      </div>
    </div>

    <div class="box-body no-padding">
      <table class="table table-bordered table-striped text-center">
        <tbody v-if="!edit">
          <tr v-for="i in [0, 3, 6, 9]" :key="i">
            <td v-for="j in [0, 1, 2]" :key="j">
              <form action="" method="POST">
                <input type="hidden" name="payment_amount" v-model="presets[j + i]" />
                <input type="hidden" name="_token" :value="csrfToken" />
                <button type="submit" class="btn btn-block btn-info btn-lg">{{ presets[j + i] }}€</button>
              </form>
            </td>
          </tr>
        </tbody>

        <tbody v-if="edit">
          <tr v-for="i in [0, 3, 6, 9]" :key="i">
            <td v-for="j in [0, 1, 2]" :key="j">
              <div class="input-group input-group-sm">
                <input
                  type="number"
                  class="form-control"
                  v-model="presets[j + i]"
                  min="6.5"
                  step="0.01"
                  max="100"
                  inputmode="numeric"
                  pattern="[0-9,.]*"
                  placeholder="0,00"
                />
                <span class="input-group-append"><span class="input-group-text">€</span></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="box-footer text-center" v-if="edit"><a class="uppercase" @click="saveAndToggleEdit">Save</a></div>

    <div class="box-footer text-center" v-if="!edit"><a class="uppercase" @click="toggleEdit">Edit</a></div>
  </div>
</template>

<script>
import config from '@/js/_config';
import { handleInputError } from '../core/errorHandling';

export default {
  props: ['scanRoute', 'restaurantId', 'partnerId'],

  data() {
    return {
      edit: false,
      presets: [],
      csrfToken: window.Tricon.csrfToken,
    };
  },

  mounted() {
    this.getPresets();
  },

  methods: {
    saveAndToggleEdit() {
      this.savePresets().then(() => {
        this.toggleEdit();
      });
    },

    toggleEdit() {
      this.edit = !this.edit;
    },

    getPresets() {
      return axios
        .get(config.urls.api.scanPresets(this.partnerId, 'restaurant', this.restaurantId))
        .then((res) => (this.presets = res.data.scan_presets || []));
    },

    savePresets() {
      const data = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => (this.presets[i] && Number(this.presets[i])) || 0);
      return axios
        .post(config.urls.api.scanPresets(this.partnerId, 'restaurant', this.restaurantId), { scan_presets: data })
        .then(() => (this.presets = data))
        .catch((e) => {
          handleInputError(e.response);
          throw e;
        });
    },
  },
};
</script>
