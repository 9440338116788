export default class CodesBulkAction {
  static querySelector = '.codes-bulk-action-select';

  constructor(domElement) {
    window.validateCodesBulkSelectAction = (event) => {
      if (domElement.value === 'nothing') return event.preventDefault();

      if (domElement.value === 'print') {
        event.preventDefault();
        CodesBulkAction.printSelectedCodes();
        return false;
      }
    };
  }

  static printSelectedCodes() {
    // Remove all existing print tables
    const existingTables = document.querySelectorAll('.print-table');
    for (const t of existingTables) t.remove();

    const codesTable = document.querySelector('#codes-table');
    if (!codesTable) return;

    // Create a copy of the codes table
    const printTable = codesTable.cloneNode(true);
    printTable.classList.add('print-table');

    // Remove all unchecked rows
    const rows = printTable.querySelectorAll('tr.code-row');
    for (const row of rows) {
      const checkbox = row.querySelector('.print-checkbox');
      if (!checkbox.checked) row.remove();
    }

    document.body.appendChild(printTable);

    window.print();
  }
}
