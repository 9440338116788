<template>
  <div class="google-map w-100" ref="map" :style="{ height }"></div>
</template>

<script>
export default {
  props: {
    lat: {
      type: [Number, String],
      default: null,
    },
    lng: {
      type: [Number, String],
      default: null,
    },
    zoom: {
      type: [Number, String],
      default: 15,
    },
    height: {
      type: String,
      default: '400px',
    },
    initializeWithMarker: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      map: null,
      bounds: null,
      marker: null,
      initialized: false,
    };
  },

  computed: {},

  emits: ['update'],

  watch: {
    lat() {
      const position = new google.maps.LatLng(this.lat, this.lng);
      this.updateMarkerPosition(position);
      this.panTo(position);
    },
    lng() {
      const position = new google.maps.LatLng(this.lat, this.lng);
      this.updateMarkerPosition(position);
      this.panTo(position);
    },
  },

  mounted() {
    if (!this.initialized) {
      this.initMap();
    }
  },

  methods: {
    handleMapClick(latLng) {
      this.$emit('update', {
        lat: latLng.lat(),
        lng: latLng.lng(),
      });
    },

    updateMarkerPosition(latLng) {
      if (!this.marker) {
        this.marker = new google.maps.Marker({
          latLng,
          map: this.map,
          draggable: true,
        });

        this.marker.addListener('dragend', (event) => this.handleMapClick(event.latLng));
      }

      this.marker.setPosition(latLng);
    },

    panTo(latLng) {
      this.map.panTo(latLng);
    },

    initMap() {
      this.initialized = true;
      this.bounds = new google.maps.LatLngBounds();

      const center = new google.maps.LatLng(this.lat, this.lng);

      this.map = new google.maps.Map(this.$refs.map, {
        center,
        zoom: this.zoom,
        mapTypeControl: false,
        streetViewControl: false,
      });

      if (this.initializeWithMarker) {
        this.updateMarkerPosition(center);
      }

      this.map.addListener('click', (event) => this.handleMapClick(event.latLng));
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
