import ConfirmDialog from '@/js/admin/plugins/ConfirmDialog.vue';

/**
 * Instantiate and show a modal
 *
 * @param Vue
 * @param contentHTML
 * @param title
 * @param target
 * @param method
 * @param _class
 */
const instantiate = (Vue, contentHTML, {
  title, target, method, _class,
}) => {
  const ConfirmDialogModal = Vue.extend({
    template: `
      <confirm-dialog class="confirm-dialog__modal" title="${title}" target="${target}" method="${method}" button-class="${_class}">
        <div class="confirm-dialog__content"></div>
      </confirm-dialog>
    `,
    mounted() {
      this.$el.querySelector('.confirm-dialog__content').innerHTML = contentHTML;
      $(this.$el).modal({
        show: true,
      });
    },
  });

  const confirmDialogModal = new ConfirmDialogModal();
  confirmDialogModal.$mount();
  document.body.appendChild(confirmDialogModal.$el);
};

/**
 * Destroy the old modal
 */
const destroy = () => {
  const confirmDialogModal = document.querySelector('.confirm-dialog__modal');
  if (confirmDialogModal) {
    confirmDialogModal.remove();
  }
};

const install = (Vue, options) => {
  Vue.component('confirm-dialog', ConfirmDialog);

  document.onclick = (e) => {
    if (!e.target.classList.contains('confirm-dialog__button')) {
      return;
    }

    const contentEl = e.target.parentElement.querySelector('.confirm-dialog__content');

    destroy();
    instantiate(Vue, contentEl.innerHTML, {
      title: e.target.getAttribute('data-title'),
      target: e.target.getAttribute('data-target'),
      method: e.target.getAttribute('data-method'),
      _class: e.target.getAttribute('data-class'),
    });
  };
};

export default { install };
