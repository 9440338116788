<template>
  <div v-if="isVisible">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    isVisible() {
      return this.radioButtonValue === this.value;
    },
  },
  inject: ['radioButtonValue'],
};
</script>
