<template>
  <div>
    <div class="form-group form-group-role">
      <label for="role">{{ __('admin.role') }}</label>
      <select id="role" name="role" class="form-control" v-model="role">
        <slot name="roles"></slot>
      </select>
    </div>
    <div v-if="role === 'pos'">
      <div class="form-group form-group-username">
        <label for="username">{{ __('web.username') }}</label>
        <input id="username" type="text" name="username" value="" class="form-control">
      </div>
      <div class="form-group form-group-password">
        <label for="password">{{ __('web.password') }}</label>
        <input id="password" type="password" name="password" value="" class="form-control">
      </div>
    </div>
    <div v-else>
      <div class="form-group form-group-email">
        <label for="email">{{ __('admin.email') }}</label>
        <input id="email" type="email" name="email" value="" class="form-control">
      </div>
    </div>
    <div v-if="!!$slots.access && role && role !== 'admin'">
      <h5 class="mb-3">{{ __('admin.access_to_facilities') }}</h5>
      <slot name="access"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  mounted() {
  },
  data() {
    return {
      role: null,
    };
  },
  methods: {},
};
</script>
