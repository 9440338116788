<template>
  <div class="error-message" v-if="visible" @click="visible = false">
    <p v-for="(error, i) in errors" :key="i">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: 'error-message',

  data() {
    return {
      visible: false,
      errors: [],
    };
  },

  mounted() {
    window.addEventListener('admin.input-error', (e) => {
      const { errors } = e.detail.data;

      this.visible = true;
      this.errors = Object.keys(errors).map((key) => errors[key][0]);
    });

    window.addEventListener('click', () => {
      this.visible = false;
    });
  },
};
</script>

<style lang="scss">
.error-message {
  background-color: red;
  color: white;
  padding: 10px 10px 2px 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 999999;
}
</style>
