<template>
  <label class="radio-button-card">
      <input
          type="radio"
          :checked="radioButtonValue === value ? 'checked' : ''"
          :name="name"
          :value="value"
          @change="(e) => onRadioButtonChange(e.target.value)"/>
      <slot></slot>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  inject: ['radioButtonValue', 'onRadioButtonChange'],
};
</script>
