<template>
  <div class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content shadow-sm">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ __('web.confirmation_for', {subject: title}) }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer d-flex justify-space-between">
          <button type="button" class="btn btn-dark" data-dismiss="modal">{{ __('mobile-app.close') }}</button>
          <form method="POST" :action="target" accept-charset="UTF-8" class="pull-left">
            <input type="hidden" name="_token" :value="csrfToken">
            <input type="hidden" name="_method" :value="method">
            <button type="submit" :class="buttonClass">{{ title }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    buttonClass: {
      type: String,
      default: 'btn btn-primary',
    },
  },
  computed: {
    csrfToken() {
      return window.Tricon?.csrfToken ?? '';
    },
  },
};
</script>
