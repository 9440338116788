import Vue from 'vue';
import config from '@/js/_config';
import {
  isIosApp, isMacDevice, isAndroidApp, isIosDevice,
} from '@/js/_ua';

export function setDeviceBasedBodyClasses() {
  if (isIosApp) document.documentElement.classList.add('is-native-app', 'is-ios-app');
  if (isAndroidApp) document.documentElement.classList.add('is-native-app', 'is-ios-app');
  if (isIosDevice) document.documentElement.classList.add('is-ios-device');
  if (isMacDevice) document.documentElement.classList.add('is-mac-device');
}

export function togglePreventScroll() {
  const CLASS_NAME = 'prevent-scroll';

  if (document.body.classList.contains(CLASS_NAME)) {
    document.body.classList.remove(CLASS_NAME);
    document.documentElement.classList.remove(CLASS_NAME);
  } else {
    document.body.classList.add(CLASS_NAME);
    document.documentElement.classList.add(CLASS_NAME);
  }
}

export function facilityOpenTimes(facility, day) {
  const weekShort = config.weekdays[day];

  if (!facility[`${weekShort}_is_open`]) return null;

  const times = [];
  const openingTimeRules = facility['opening_time_rules'].filter((d) => d.day === weekShort);
  for (let i = 0; i < openingTimeRules.length; i++) {
    const opens = moment(openingTimeRules[i].start, 'HH:mm:ss').format('HH:mm');
    const closes = moment(openingTimeRules[i].end, 'HH:mm:ss').format('HH:mm');
    times.push(`${opens} - ${closes}`);
  }

  if (times.length < 1) return null;

  return times.join(', ');
}

export function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function groupTrainingLength(groupTrainingOccurrence) {
  const hours = groupTrainingOccurrence.duration.substring(0, 2);
  const minutes = groupTrainingOccurrence.duration.substring(3, 5);
  const totalMinutes = Number(hours) * 60 + Number(minutes);
  return `${moment.duration(totalMinutes, 'minutes').as('minutes')} min`;
}

export function formatTime(string, format) {
  return moment(string).format(format);
}

export function spotsLeft(groupTrainingOccurrence) {
  return groupTrainingOccurrence.total_spots - groupTrainingOccurrence.bookings_count;
}

export function canCancelGroupTraining(groupTrainingOccurrence) {
  if (groupTrainingOccurrence.uses_tickets) return false;
  const starts = moment(groupTrainingOccurrence.starts_at);
  return starts.diff(moment(), 'hours') > 24;
}

export function getGoogleMapsLink(lat, lng) {
  return `//maps.google.com/maps/search/?api=1&query=${lat},${lng}`;
}

export function scrollTo(to, duration) {
  const element = document.body;
  const start = element.scrollTop;
  const change = to - start;
  const increment = 1;
  let currentTime = 0;

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) setTimeout(animateScroll, increment);
  };

  animateScroll();
}

export function toast(msg, type) {
  Event.$emit('toast', { msg, type });
}

export function handleApiErrors(err) {
  const obj = err.response.data;
  for (const property in obj) {
    if (obj[property]) {
      for (const msg of obj[property]) toast(msg, 'error');
    }
  }
}

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const deg2rad = (deg) => deg * (Math.PI / 180);

  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

export const calculateDistanceToFacility = (facility) => {
  if (!facility) return;

  const fLat = facility.lat;
  const fLng = facility.lng;

  if (fLat === undefined || fLng === undefined) return;

  let lat = 0;
  let lng = 0;

  const userLocation = window.TriconHelpers.getUserLocation();
  if (userLocation) {
    lat = userLocation.lat;
    lng = userLocation.lng;
  }

  return lat > 0 && lng > 0 && facility && facility.lat > 0 && facility.lng > 0
    ? `${calculateDistance(facility.lat, facility.lng, lat, lng).toFixed(1)} km`
    : undefined;
};

export const isHistoricalGroupTraining = (groupTrainingOccurrence) => {
  const startsAt = moment(groupTrainingOccurrence.starts_at);
  const now = moment();
  return now.diff(startsAt) > 0;
};

export const instantiateGlobalComponents = (components) => {
  Object.entries(components).forEach(([kebabCaseName, Class]) => {
    const querySelector = Class?.querySelector ?? `[data-component='${kebabCaseName}']`;
    document.querySelectorAll(querySelector).forEach((domElement) => new Class(domElement));
  });
};

export const initFitcardHelpers = () => {
  window.TriconHelpers = {};

  FitcardHelpers.supportsLocalStorage = () => typeof Storage !== 'undefined';

  FitcardHelpers.shouldUpdateLocation = () => {
    if (!FitcardHelpers.supportsLocalStorage()) return;
    let locationData = localStorage.getItem('USER_LOCATION_DATA');
    if (!locationData) return true;
    locationData = JSON.parse(locationData);
    return new Date().getTime() - locationData.time > 60 * 60 * 1000; // Check if 60 minutes has passed
  };

  FitcardHelpers.setUserLocation = (lat, lng) => {
    if (!FitcardHelpers.supportsLocalStorage() || !FitcardHelpers.shouldUpdateLocation()) return;

    const locationData = {
      lat,
      lng,
      time: new Date().getTime(),
    };

    localStorage.setItem('USER_LOCATION_DATA', JSON.stringify(locationData));
  };

  FitcardHelpers.getUserLocation = () => {
    if (!FitcardHelpers.supportsLocalStorage()) return;
    let locationData = localStorage.getItem('USER_LOCATION_DATA');
    if (!locationData) return true;
    locationData = JSON.parse(locationData);
    return { lat: locationData.lat, lng: locationData.lng };
  };

  FitcardHelpers.queryUserLocationOnWeb = (requestIp) => {
    const externalCoordsListener = () => {
      const response = JSON.parse(this.response);
      FitcardHelpers.setUserLocation(response.latitude, response.longitude);
    };

    const loadExternalCoords = () => {
      const oReq = new XMLHttpRequest();
      oReq.addEventListener('load', externalCoordsListener);
      oReq.open('GET', `https://freegeoip.net/json/${requestIp}`);
      oReq.send();
    };

    if (navigator.geolocation && FitcardHelpers.supportsLocalStorage()) {
      navigator.geolocation.getCurrentPosition(
        (position) => FitcardHelpers.setUserLocation(position.coords.latitude, position.coords.longitude),
        () => loadExternalCoords(),
      );
    } else {
      loadExternalCoords();
    }
  };
};
