import DynamicFormHandler from './DynamicFormHandler.js';
import EmployeePieChart from './EmployeePieChart.js';
import FileUploadHandler from './FileUploadHandler.js';
import PaymentBtn from './PaymentBtn.js';
import NordeaPaymentBtn from './NordeaPaymentBtn.js';
import NordeaEInvoiceButton from './NordeaEInvoiceButton.js';
import ParentSettingsInput from './ParentSettingsInput.js';
import ImagePicker from './ImagePicker.js';
import ScrollMessageHandler from './ScrollMessageHandler.js';
import CodesBulkAction from './CodesBulkAction.js';
import DataTable from './DataTable.js';
import AreaChart from './AreaChart.js';
import SelectAllCheckbox from './SelectAllCheckbox.js';

export default {
  'select-all-checkbox': SelectAllCheckbox,
  'dynamic-form-handler': DynamicFormHandler,
  'employee-pie-chart': EmployeePieChart,
  'file-upload-handler': FileUploadHandler,
  'payment-btn': PaymentBtn,
  'nordea-payment-btn': NordeaPaymentBtn,
  'nordea-e-invoice-btn': NordeaEInvoiceButton,
  'parent-settings-input': ParentSettingsInput,
  'image-picker': ImagePicker,
  'scroll-message-handler': ScrollMessageHandler,
  'codes-bulk-action': CodesBulkAction,
  'data-table': DataTable,
  'area-chart': AreaChart,
};
