export const BUSINESS_EMPLOYEE_PAYMENT_COMPLETED = 'BusinessEmployeePaymentCompleted';

const broadcast = {
  namespaced: true,
  state: () => ({
    messages: {
      [BUSINESS_EMPLOYEE_PAYMENT_COMPLETED]: [],
    },
  }),
  getters: {
    updatedWallets: ({ messages }) => messages[BUSINESS_EMPLOYEE_PAYMENT_COMPLETED].reduce((wallets, message) => {
      message.updatedWallets.forEach((wallet) => wallets[wallet.id] = wallet);

      return wallets;
    }, {}),
    orders: ({ messages }) => messages[BUSINESS_EMPLOYEE_PAYMENT_COMPLETED].map((message) => message.order),
  },
  mutations: {
    addMessage(state, { message, type }) {
      if (type in state.messages) {
        state.messages[type].push(message);
      }
    },
  },
  actions: {
    addMessage({ commit }, data) {
      commit('addMessage', data);
    },
  },
};

export default broadcast;
