export default class ParentSettingsInput {
  static querySelector = '.parent-setting-input';

  constructor(domElement) {
    const checkbox = domElement.querySelector('.parent-checkbox input:not([type=hidden])');
    const childInput = domElement.querySelector(
      '.child-input input:not([type=hidden]), .child-input select:not([type=hidden])',
    );
    const shouldBeWritableIfChecked = domElement.getAttribute('data-enable-if-checked') === 'true';

    function disableChildInput() {
      childInput.type === 'checkbox'
        ? childInput.setAttribute('disabled', true)
        : childInput.setAttribute('readonly', true);
    }

    function enableChildInput() {
      if (childInput.getAttribute('readonly') || childInput.getAttribute('disabled')) {
        childInput.removeAttribute('readonly');
        childInput.removeAttribute('disabled');
      }
    }

    function updateState() {
      if (checkbox.checked) {
        if (shouldBeWritableIfChecked) {
          enableChildInput();
        } else {
          disableChildInput();
        }
      } else if (shouldBeWritableIfChecked) {
        disableChildInput();
      } else {
        enableChildInput();
      }
    }

    updateState();

    checkbox.addEventListener('click', () => {
      updateState();
    });
  }
}
