<template>
  <div class="turbolinks-loader" :class="{ enabled }" data-turbolinks-permanent>
    <div class="background-overlay"></div>
    <div class="loading-icon"></div>
  </div>
</template>

<script>
export default {
  name: '',

  data() {
    return {
      enabled: false,
    };
  },

  mounted() {
    document.addEventListener('turbolinks:click', () => {
      this.enable();
    });
    /*    document.addEventListener("turbolinks:load", () => {
      this.disable();
    }); */
  },

  methods: {
    enable() {
      this.enabled = true;
    },

    disable() {
      this.enabled = false;
    },
  },
};
</script>

<style lang="scss">
.turbolinks-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 300ms;

  .background-overlay {
    background-color: black;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &.enabled {
    z-index: 10;
    opacity: 1;
  }
}
</style>
