import Chart from 'chart.js';
import Lang from '@/js/_lang';

export default class EmployeePieChart {
  static querySelector = '#employee-chart';

  constructor(domElement) {
    const employees = JSON.parse(domElement.dataset.json);

    const employeePieChart = new Chart(domElement, {
      type: 'pie',
      data: {
        datasets: [
          {
            data: [employees.green, employees.yellow, employees.orange, employees.red],
            backgroundColor: ['green', 'yellow', 'orange', 'red'],
          },
        ],
        labels: [
          Lang.get('web.have_plan_over_60_days'),
          Lang.get('web.plan_expiring_in_60_days'),
          Lang.get('web.plan_expiring_in_30_days'),
          Lang.get('web.no_plan'),
        ],
      },
      options: {
        legend: {
          position: 'right',
          align: 'center',
        },
      },
    });

    $(domElement).click((evt) => {
      const activePoints = employeePieChart.getElementsAtEvent(evt)[0];

      if (typeof activePoints === 'undefined') return;
      if (typeof activePoints._index === 'undefined') return;

      const actions = {
        0: {
          more: 60,
          less: false,
        },
        1: {
          more: 30,
          less: 60,
        },
        2: {
          more: 0,
          less: 30,
        },
        3: {
          more: false,
          less: 0,
        },
      };

      const action = actions[activePoints._index];
      const expiresInLessThan = document.querySelector('[name=expires_in_less_than]');
      expiresInLessThan.value = action.less !== false ? action.less : '';
      document.querySelector('[name=expires_in_more_than]').value = action.more !== false ? action.more : '';
      expiresInLessThan.closest('form').submit();
    });
  }
}
