<template>
  <div class="mb-3">
    <input type="hidden" name="map_type" :value="tab">
    <div class="d-flex">
      <label class="mr-2">{{ __('admin.location_on_map') }}</label>
      <div
          data-toggle="tooltip"
          data-placement="bottom"
          :title="__('admin.location_on_map_guide')"><i class="fa fa-question-circle"></i></div>
    </div>
    <ul class="nav nav-pills mb-3" role="tablist">
      <li class="nav-item">
        <a class="nav-link"
           :class="{active: tab === 'default'}"
           href="#default-location" role="tab" aria-controls="default-location"
           :aria-selected="tab === 'default'"
           @click.prevent="tab = 'default'"
        >
          {{ __('admin.default_location') }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           :class="{active: tab === 'custom'}"
           href="#custom-location" role="tab" aria-controls="custom-location"
           :aria-selected="tab === 'custom'"
           @click.prevent="tab = 'custom'"
        >
          {{ __('admin.custom_location') }}
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div
          v-if="tab === 'default'"
          class="tab-pane fade show active"
          id="default-location"
          role="tabpanel"
          aria-labelledby="default-location-tab"
      >
        <div class="form-group admin-location-picker">
          <google-map-embed :search="searchAddress" :zoom="15"></google-map-embed>
        </div>
      </div>
      <div
          v-if="tab === 'custom'"
          class="tab-pane fade show active"
          id="custom-location"
          role="tabpanel"
          aria-labelledby="custom-location-tab"
      >
        <div class="row mb-3">
          <div class="col">
            <label>{{ __('admin.latitude') }}</label>
            <input type="text" class="form-control" v-model="location.lat" name="lat" />
          </div>
          <div class="col">
            <label>{{ __('admin.longitude') }}</label>
            <input type="text" class="form-control" v-model="location.lng" name="lng" />
          </div>
        </div>
        <google-map-api
            :lat="hasLocation() ? location.lat : fallbackLocation.lat"
            :lng="hasLocation() ? location.lng : fallbackLocation.lng"
            :initialize-with-marker="hasLocation()"
            height="400px"
            :zoom="9"
            @update="handleUpdateLocation"
        ></google-map-api>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'location-picker',
  props: {
    initialLat: {
      type: [Number, String],
      default: null,
    },
    initialLng: {
      type: [Number, String],
      default: null,
    },
    initialMapType: {
      type: String,
      default: 'default',
    },
    initialAddress: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tab: this.initialMapType,
      location: {
        lat: this.initialLat,
        lng: this.initialLng,
      },
      fallbackLocation: {
        lat: 60.2946727,
        lng: 24.9593556,
      },
      searchAddress: this.initialAddress,
      inputs: {
        address: null,
        city: null,
        zip_code: null,
      },
    };
  },
  emits: ['update'],
  mounted() {
    this.inputs.address = document.querySelector('input[name="address"]');
    this.inputs.city = document.querySelector('input[name="city"]');
    this.inputs.zip_code = document.querySelector('input[name="zip_code"]');

    Object.values(this.inputs)
      .filter((input) => input)
      .forEach((input) => input?.addEventListener('change', () => this.updateSearchAddress()));
  },

  methods: {
    hasLocation() {
      return !!this.location.lat && !!this.location.lng;
    },

    handleUpdateLocation(location) {
      this.location = location;
    },

    updateSearchAddress() {
      this.searchAddress = [
        this.inputs.address?.value,
        this.inputs.zip_code?.value,
        this.inputs.city?.value,
      ].filter((value) => value).join(', ');
    },
  },
};
</script>
