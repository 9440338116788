<template>
  <div>
    <vue-dropzone
        class="vue-dropzone--admin"
        :class="{
          'is-invalid': errors[name],
          'vue-dropzone--full': isFull,
        }"
        ref="dropzone"
        id="dropzone"
        :options="options"
        :useCustomSlot=true
        @vdropzone-removed-file="() => onFileRemoved()"
        @vdropzone-error="(file, response, xhr) => onError(file, response, xhr)"
        @vdropzone-file-added="() => errors = {}"
        @vdropzone-max-files-reached="() => isFull = true"
        @vdropzone-max-files-exceeded="() => isFull = true"
    >
      <div class="dropzone-custom-content">
        <h3 class="h5 dropzone-custom-title">{{ __('admin.drag_and_drop_to_upload') }}</h3>
        <div class="subtitle">{{ __('admin.or_click_to_select_file') }}</div>
      </div>
    </vue-dropzone>
    <div class="invalid-feedback" v-for="(error, i) in errors[name]" :key="i">
      {{ error }}
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  name: 'dropzone',
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    name: {
      type: [String],
      default: null,
    },
    fileUrl: {
      type: [String],
      default: null,
    },
    updateUrl: {
      type: [String],
      default: null,
    },
    deleteUrl: {
      type: [String],
      default: null,
    },
  },
  data() {
    return {
      isFull: false,
      errors: {},
      options: {
        url: this.updateUrl,
        addRemoveLinks: true,
        paramName: this.name,
        thumbnailWidth: null,
        maxFilesize: 4 * 1024,
        maxFiles: 1,
        withCredentials: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        headers: {
          'X-CSRF-TOKEN': window.Tricon.csrfToken,
        },
        dictRemoveFile: this.__('admin.remove_file'),
        dictCancelUpload: this.__('admin.cancel_upload'),
      },
    };
  },
  async mounted() {
    if (this.fileUrl) {
      try {
        const file = await this.loadFileFromUrl(this.fileUrl);
        this.$refs.dropzone.manuallyAddFile(file, this.fileUrl);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  },
  methods: {
    async loadFileFromUrl(url) {
      const response = await axios.get(url, { responseType: 'blob' });

      return new File([response.data], this.fileUrl, {
        type: response.data.type,
      });
    },
    async deleteRemoteFile() {
      try {
        await axios.delete(this.deleteUrl);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
    onFileRemoved() {
      this.isFull = false;
      this.deleteRemoteFile();
    },
    onError(file, response, xhr) {
      this.errors = response.errors ?? {};
      this.$refs.dropzone.removeAllFiles();
      // eslint-disable-next-line no-console
      console.error(file, response, xhr);
    },
  },
};
</script>
