const BUSINESS = 'business';

const user = {
  state: () => ({
    type: window.Business ? BUSINESS : null,
  }),
  getters: {
    data: (state) => {
      switch (state.type) {
        case BUSINESS:
          return window.Business ?? {};
      }

      return {};
    },
  },
  mutations: {},
  actions: {},
};

export default user;
