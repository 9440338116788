export default class PaymentBtn {
  static querySelector = '[data-js-action=payment-btn]';

  constructor(domElement) {
    $(domElement).click(() => {
      $.get(window.Tricon.config.paymentFormRoute).then((res) => {
        const $paymentForm = $('#payment-form');
        $paymentForm.html(res);
        $paymentForm.find('form').submit();
      });
    });
  }
}
