import _ from 'lodash';

export default class FileUploadHandler {
  static querySelector = '[data-ajaxupload]';

  constructor(domElement) {
    this.file = null;
    this.files = [];
    this.uploadingFiles = {};
    this.$uploadedFilesContainer = null;
    this.resetFileChange = false;
    this.domElement = domElement;

    this.uploadFileProgressHtml = `<div class="uploaded-file uploading-in-progress">
        <div class="file-content">
            <span class="file-upload-progress">0%</span>
        </div>
      </div>`;

    setTimeout(() => this.initEvents(), 500);
  }

  initDraggableOrder = () => {
    this.$uploadedFilesContainer.sortable({
      change: _.debounce(this.updateSortChange, 2500),
    });
    this.$uploadedFilesContainer.disableSelection();
  };

  getFileIdOrder = () => {
    const $files = this.$uploadedFilesContainer.find('> .uploaded-file');
    const fileOrder = [];

    $files.each((i, val) => {
      fileOrder.push($(val).data('file-id'));
    });

    return fileOrder;
  };

  updateSortChange = () => {
    $.ajax({
      type: 'POST',
      url: '/api/v1/admin/upload/collaboration-logo/update-order',
      error: () => {
        console.error('Order could not be updated on server!');
      },
      data: {
        order: this.getFileIdOrder(),
      },
      async: true,
      cache: false,
      contentType: 'application/x-www-form-urlencoded',
      dataType: 'json',
      // processData: false,
      timeout: 60000,
    });
  };

  initEvents = () => {
    const $uploadWrapper = $(this.domElement);
    this.$fileUploadInput = $uploadWrapper.find('input[type=file]');

    this.$uploadedFilesContainer = $('.file-upload-and-preview').find('.uploaded-files');

    this.initDraggableOrder();

    this.$uploadedFilesContainer.find('.uploaded-file').each((key, val) => {
      this.initFileButtonEvents($(val));
    });

    this.$fileUploadInput.on('change', (e) => {
      this.files = $(e.currentTarget)[0].files;
      this.doUpload();
    });

    this.$fileUploadInput.on('dragover', () => {
      $uploadWrapper.addClass('dragged');
    });

    this.$fileUploadInput.on('dragleave ondragleave ondrop', () => {
      $uploadWrapper.removeClass('dragged');
    });
  };

  getType = (file) => file.type;

  getSize = (file) => file.size;

  getName = (file) => file.name;

  initFileButtonEvents = ($selector = null) => {
    if (!$selector) return;

    const $removeBtn = $selector.find('.remove-btn');
    const imageId = $removeBtn.data('delete-id');

    $removeBtn.on('click', () => {
      $.ajax({
        type: 'GET',
        url: `/api/v1/admin/upload/detach-collaboration-logo/${imageId}`,
        success: () => {
          $selector.remove();
        },
        error: () => {
          console.error('File could not be removed!');
        },
        async: true,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000,
      });
    });
  };

  uploadSingleFile = (file) => {
    const formData = new FormData();

    formData.append('file', file, this.getName(file));
    formData.append('upload_file', true);

    let $currentFileUploadContainer = $(this.uploadFileProgressHtml).appendTo(this.$uploadedFilesContainer);

    const currentUploadingFile = (this.uploadingFiles[this.getName(file)] = {
      uploadContainer: $currentFileUploadContainer,
      uploadProgress: null,
    });

    setTimeout(() => {
      currentUploadingFile.uploadProgress = currentUploadingFile.uploadContainer.find('.file-upload-progress');
    }, 5);

    $.ajax({
      type: 'POST',
      url: '/api/v1/admin/upload/collaboration-logo',
      xhr: () => {
        const myXhr = new window.XMLHttpRequest();
        if (myXhr.upload) myXhr.upload.addEventListener('progress', (e) => this.progressHandling(e, currentUploadingFile), false);
        return myXhr;
      },
      success: (data) => {
        const $newUploadContainer = $(data.preview_partial);
        $currentFileUploadContainer.replaceWith($newUploadContainer);
        $currentFileUploadContainer = $newUploadContainer;

        setTimeout(() => {
          this.initFileButtonEvents($newUploadContainer);
        }, 200);
      },
      error: () => {
        $currentFileUploadContainer.remove();
      },
      async: true,
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      timeout: 60000,
    });
  };

  doUpload = () => {
    let uploadOffset = 0;
    const offsetAdder = 200;

    for (let i = 0; i < this.files.length; i++) {
      setTimeout(() => this.uploadSingleFile(this.files[i]), uploadOffset);
      uploadOffset += offsetAdder;
    }

    setTimeout(() => this.$fileUploadInput.val(''), uploadOffset + 1000);
  };

  progressHandling = (e, currentUploadingFile) => {
    let percent = 0;
    const position = e.loaded || e.position;
    const { total } = e;
    if (e.lengthComputable) percent = Math.ceil((position / total) * 100);

    if (currentUploadingFile.uploadProgress) {
      currentUploadingFile.uploadProgress.html(`${percent}%`);
    }
  };
}
