import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import broadcast from '@/js/admin/vuex/modules/broadcast';
import user from '@/js/admin/vuex/modules/user';

Vue.use(Vuex);

const store = new Store({
  modules: {
    user,
    broadcast,
  },
});

export default store;
