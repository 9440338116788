<template>
  <div class="updatable-element__container" :key="updateIndex" ref="container">
    <div class="updatable-element" v-if="content" v-html="content"/>
    <div class="updatable-element" v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'updatable-element',
  props: {
    updateUrl: {
      type: String,
      default: null,
    },
    updateInterval: {
      type: [Number, String],
      default: 3000,
    },
    updateMode: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      updateIndex: 0,
      interval: null,
      content: null,
      lastData: {},
    };
  },
  emits: ['update'],
  created() {
    if (!this.updateUrl) {
      return;
    }

    this.interval = setInterval(
      this.fetchTableData,
      parseInt(this.updateInterval, 10),
    );

    if (this.updateMode === 'immediate') {
      this.fetchTableData();
    }
  },
  updated() {
    this.populateForms();
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    /**
     * Get a form element by its name
     * @param formId
     * @param elementName
     * @returns {Element}
     */
    getFormElement(formId, elementName) {
      let field = this.$refs.container.querySelector(`#${formId} [name="${elementName}"]`);

      if (!field) {
        field = this.$refs.container.querySelector(`[name="${elementName}"][form="${formId}"]`);
      }

      return field;
    },
    saveCurrentData() {
      const forms = [...this.$refs.container.querySelectorAll('form')];

      this.lastData = {};

      forms.forEach((form) => {
        const formData = new FormData(form);
        const formId = form.getAttribute('id');

        const modifiedData = [...formData.entries()].map(([key, value]) => {
          const field = this.getFormElement(formId, key);

          // Also save indeterminate state for checkboxes.
          if (field?.type === 'checkbox' && field.indeterminate) {
            value = 'indeterminate';
          }

          // Save the active state of the field.
          value = {
            value,
            isActive: document.activeElement === field,
          };

          return [key, value];
        });

        this.lastData = {
          ...this.lastData,
          [formId]: Object.fromEntries(modifiedData),
        };
      });
    },
    setContent(newContent) {
      this.saveCurrentData();
      this.content = newContent;
    },
    populateForms() {
      Object.entries(this.lastData).forEach(([formId, formData]) => {
        this.populateForm(formId, formData);
      });
    },
    populateForm(formId, formData) {
      Object.entries(formData).forEach(([key, { value, isActive }]) => {
        const field = this.getFormElement(formId, key);

        if (field) {
          if (isActive) {
            this.$nextTick(() => {
              field.focus();
            });
          }

          switch (field.type) {
          case 'checkbox':
            field.checked = value === 'true' || value === 'on' || value === 'indeterminate';
            field.indeterminate = value === 'indeterminate';
            break;
          case 'radio':
            field.checked = field.value === value;
            break;
          case 'select-one':
            field.value = value;
            break;
          default:
            field.value = value;
          }
        }
      });
    },
    async fetchTableData() {
      try {
        const response = await axios.get(this.updateUrl);
        this.$emit('update', response.data);
        this.setContent(response.data);
        this.updateIndex += 1;
        this.$forceUpdate();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
