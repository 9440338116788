export const initShowHidePassword = (selector = '.show-hide-password') => {
  const toggleButtons = document.querySelectorAll(selector);

  [...toggleButtons].forEach((button) => {
    const dataFor = button.getAttribute('data-for');
    const passwordInput = document.querySelector(`#${dataFor}`);

    if (!passwordInput) {
      return;
    }

    if (button.classList.contains('d-none')) {
      button.classList.remove('d-none');
    }

    button.addEventListener('click', () => {
      const type = passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
      passwordInput.setAttribute('type', type);

      if (type === 'password') {
        button.querySelector('i')?.classList.remove('fa-eye-slash');
        button.querySelector('i')?.classList.add('fa-eye');
      } else {
        button.querySelector('i')?.classList.add('fa-eye-slash');
        button.querySelector('i')?.classList.remove('fa-eye');
      }
    });
  });
};

export default { initShowHidePassword };
