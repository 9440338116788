export default class NordeaEInvoiceButton {
  static querySelector = '[data-js-action=nordea-e-invoice-btn]';

  constructor(domElement) {
    $(domElement).click(() => {
      $.get(window.Tricon.config.nordeaEInvoiceFormRoute).then((res) => {
        const $paymentForm = $('#nordea-e-invoice-form');
        $paymentForm.html(res);
      });
    });
  }
}
